import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import AboutUs from "../routes/home/AboutUs";
import ArtSection from "../routes/home/ArtSection";
import Featured from "../routes/home/Featured";
import IconRow from "../routes/home/IconRow";
import LandingSection from "../routes/home/LandingSection";
import ProductInfoSection from "../routes/home/ProductInfoSection";
import SocialSection from "../routes/home/SocialSection";
import Testimonials from "../routes/home/Testimonials";

const IndexPage = () => (
  <Layout>
    <Seo title="Wavy Blue" />
    <LandingSection />
    <ProductInfoSection />
    <IconRow />
    <ArtSection />
    <Testimonials />
    <AboutUs />
    <Featured />
    <SocialSection />
  </Layout>
);

export default IndexPage;
