import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { HairFour, HairOne, HairThree, HairTwo } from "../../components/library/Images";
import PictureCarousel from "../../components/library/PictureCarousel";
import { Text, Title } from "../../components/library/Text";
import useMobile from "../../hooks/useMobile";

const Wrapper = styled.div`
  width: 100%;
  background: white;
  text-align: center;
  padding: 105px 15px;
  max-width: 1230px;
  margin: 0 auto;
`;

const ArtTitle = styled(Title)`
  margin-bottom: 12px;
`;

const SubHeader = styled.h2`
  font-weight: bold;
  font-size: 55px;
  line-height: 66px;
  margin: 0 0 20px 0;
`;

const InfoContainer = styled.div`
  margin-bottom: 54px;
`;

const Info = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: rgba(14, 14, 14, 0.6);
`;

const BoldInfo = styled(Info)`
  font-weight: bold;
`;

const Illustration = styled.div`
  margin: 0 auto 38.45px auto;

  mask {
    mask-type: alpha;
  }
`;

const Caption = styled.div`
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #0e0e0e;
`;

const ArtCarousel = styled(PictureCarousel)`
  margin-bottom: 70px;
  img {
    height: 100%;
  }

  .carousel-item {
    height: 240px;
  }

  .carousel-indicators {
    bottom: -40px;
    margin-bottom: 0;
  }
`;

const CaptionText = styled(Text)`
  color: #090c0e;
  font-weight: 600;
  margin-bottom: 19px;
`;

const items = [
  {
    src: HairOne,
    altText: "Slide 1",
    key: "1",
    caption: "Classic Spinnin' Waves"
  },
  {
    src: HairTwo,
    altText: "Slide 2",
    key: "2",
    caption: "Beard and Long on Top"
  },
  {
    src: HairThree,
    altText: "Slide 3",
    key: "3",
    caption: "Temp Fade with Curl on Top"
  },
  {
    src: HairFour,
    altText: "Slide 4",
    key: "4",
    caption: "Freeform Locs with the Fade"
  }
];

const MobileCarousel = () => {
  const isMobile = useMobile();
  const [index, setIndex] = useState(0);

  const caption = items[index].caption;
  const onIndexChange = (value?: number) => setIndex(value);

  return !isMobile ? null : (
    <div data-aos="fade-down">
      <ArtCarousel items={items} index={index} onIndexChange={onIndexChange} />
      <CaptionText>{caption}</CaptionText>
      <Info>
        Whether you’re 4A, 3B or 2C... long on top or growing locs, Wavy Blue will complement and
        enhance your routine.
      </Info>
    </div>
  );
};

const ArtLayout = () => {
  const isMobile = useMobile();

  return isMobile ? null : (
    <>
      <InfoContainer>
        <Info data-aos="fade-down">
          We designed Wavy Blue to be inclusive by design. From 3A to 4C, long on top, growing locs,
          new to this or true to this, our products will complement and enhance your daily routine.
        </Info>
      </InfoContainer>
      <Container fluid data-aos="fade-down">
        <Row>
          <Col md={3}>
            <Illustration>
              <HairOne />
            </Illustration>
            <Caption>Classic Spinnin' Waves</Caption>
          </Col>
          <Col md={3}>
            <Illustration>
              <HairTwo />
            </Illustration>
            <Caption>Beard and Long on Top</Caption>
          </Col>
          <Col md={3}>
            <Illustration>
              <HairThree />
            </Illustration>
            <Caption>Temp Fade with Curl on Top</Caption>
          </Col>
          <Col md={3}>
            <Illustration>
              <HairFour />
            </Illustration>
            <Caption>Freeform Locs with the Fade</Caption>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const ArtSection = () => (
  <Wrapper>
    <ArtTitle data-aos="fade-down">Is this for me?</ArtTitle>
    <SubHeader data-aos="fade-down">All Ways to Wavy</SubHeader>
    <MobileCarousel />
    <ArtLayout />
  </Wrapper>
);

export default ArtSection;
