import React from "react";
import styled from "styled-components";
import Ocean from "../../components/ocean/Ocean";
import MainCarousel from "./MainCarousel";

const MainContent = styled.div`
  position: relative;
`;

const OceanWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const LandingSection = () => (
  <MainContent>
    <MainCarousel />
    <OceanWrapper>
      <Ocean />
    </OceanWrapper>
  </MainContent>
);

export default LandingSection;
