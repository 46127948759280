import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useContext } from "react";
import { Col as Column, Container, Row } from "reactstrap";
import styled from "styled-components";
import { Button } from "../../components/library/Button";
import { SubHeader, Title } from "../../components/library/Text";
import { CartContext, CartProviderProps } from "../../contexts/CartContext";
import useBuyNow from "../../hooks/useBuyNow";
import useMobile from "../../hooks/useMobile";

const Content = styled.div`
  background: white;
  margin: 105px 0;

  @media screen and (max-width: 768px) {
    margin: 27px 0 0;
  }
`;

const RowWrapper = styled.div`
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
`;

const MobileTitle = styled.div`
  text-align: center;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const RowContainer = styled(Container)`
  padding: 0 15px;

  @media screen and (max-width: 768px) {
    padding: 36px 0 66px;
  }
`;

const TextCol = styled(Column)`
  z-index: 2;

  @media screen and (max-width: 768px) {
    order: 2;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const TextBlock = styled.div``;

const ImageCol = styled(Column)`
  text-align: center;

  @media screen and (max-width: 768px) {
    order: 1;
    margin-bottom: 12px;
  }
`;

const Info = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0.4rem;

  @media screen and (min-width: 768px) {
    max-width: 495px;
  }
`;

const InfoBlock = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);

  li {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    max-width: 495px;
    margin-bottom: 54px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 495px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const FirstRowButtons = styled.div`
  display: flex;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LearnLink = styled(Link)`
  @media screen and (min-width: 1001px) and (max-width: 1087px) {
    width: 100%;
  }
`;

const LearnMoreButton = styled(Button)`
  height: 46px;
  width: 140px;
  color: #4b5a62;
  background-color: white;
  border: 1px solid #4b5a62;
  margin-left: 17px;

  :active,
  :hover {
    color: white;
    background-color: #4b5a62;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }

  @media screen and (min-width: 1001px) and (max-width: 1087px) {
    width: calc(100% - 17px);
    padding: 0.75rem 0.5rem;
  }
`;

const BuyNowButton = styled(Button)<{ onClick: () => void }>`
  height: 46px;
  width: 100%;
  padding: 12px 0.75rem;
  color: #4b5a62;
  background-color: #bce0f6;

  :active,
  :hover {
    color: white;
    background-color: #4b5a62;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 560px;
  max-height: 560px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    max-height: 340px;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const MoreInfo = () => (
  <>
    <Info>
      Made with black hair ready ingredients, Wavy Blue's products take care of the small things, so
      you can keep making waves, everyday, everywhere. Our Curl & Coil Balm:
    </Info>
    <InfoBlock>
      <ul>
        <li>hydrates, styles and conditions</li>
        <li>contains natural ingredients including: coconut extract, bamboo and ginseng</li>
      </ul>
    </InfoBlock>
  </>
);

const DesktopInfo = () => (
  <div>
    <Title>Carefully Crafted</Title>
    <SubHeader>Discover your new favorite formula</SubHeader>
    <MoreInfo />
  </div>
);

const MobileInfo = () => (
  <div>
    <MoreInfo />
  </div>
);

const ButtonModule = () => {
  // hooks
  // const valueRef = useRef(1);
  const buyNow = useBuyNow();
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { loading } = cartContext.state;

  // functions
  // const onClick = useCallback(option => {
  //   valueRef.current = option.key;
  // }, []);

  const onBuyClick = useCallback(() => {
    // do the network request to add to the cart, and then dispatch upon success
    // const sku = `${valueRef.current === 1 ? "TWO" : "FOUR"}_OZ`;
    buyNow("FOUR_OZ");
  }, [buyNow]);

  return (
    <ButtonContainer data-aos="fade">
      <FirstRowButtons>
        {/* <PriceDropdown onClick={onClick} /> */}
        <BuyNowButton disabled={loading} onClick={onBuyClick}>
          <span>Buy Now{/* <sup>*</sup> */}</span>
        </BuyNowButton>
        <LearnLink to="/products">
          <LearnMoreButton>Learn More</LearnMoreButton>
        </LearnLink>
      </FirstRowButtons>
      {/* <BuyNowButton disabled={loading} onClick={onBuyClick}>
        <span>Buy Now</span>
      </BuyNowButton> */}
      {/* <PreOrderInfo /> */}
    </ButtonContainer>
  );
};

const ProductInfoSection = () => {
  const isMobile = useMobile();
  return (
    <Content>
      <RowWrapper>
        <MobileTitle data-aos="fade">
          <Title>Wavy Blue's Formula</Title>
          <SubHeader>Curl & Coil Balm</SubHeader>
        </MobileTitle>
        <RowContainer fluid data-aos="fade-down">
          <Row>
            <TextCol md={6}>
              <TextBlock>
                {isMobile ? null : <DesktopInfo />}
                <ButtonModule />
                {isMobile ? <MobileInfo /> : null}
              </TextBlock>
            </TextCol>
            <ImageCol md={6}>
              <ImageContainer>
                <StaticImage
                  src="https://static.wavyformula.com/images/wavy-tan-cross.jpg"
                  alt="black hands holding jar"
                  imgStyle={{ backgroundColor: "#c5def5" }}
                />
              </ImageContainer>
            </ImageCol>
          </Row>
        </RowContainer>
      </RowWrapper>
    </Content>
  );
};

export default ProductInfoSection;
