import React from "react";
import styled from "styled-components";
import LargeCarousel from "../../components/library/LargeCarousel";
import { ImageConstants } from "../../constants/Images";
import useWebP from "../../hooks/useWebp";
import { getFileParts } from "../../utils/browser";

const Carousel = styled(LargeCarousel)<{ $backgroundImage: string }>`
  position: relative;

  .carousel-caption {
    justify-content: center;
  }

  ::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
  }

  ::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
    background-color: #8fd4f1;
    background-image: url(${props => props.$backgroundImage});
    background-size: cover;
  }
`;

const items = [
  {
    caption: " ",
    subQuote: "- Sean Marcus",
    content: "“The Curl & Coil Balm has given me the confidence and style I've been looking for”"
  },
  {
    caption: " ",
    subQuote: "- Tariq Thornton",
    content: "“After using the Curl & Coil Balm, my hair looks and feels healthier than before”"
  },
  {
    caption: " ",
    subQuote: "- Will Barton",
    content: "“Wavy Blue works seamlessly with my daily hair care routine”"
  },
  {
    caption: " ",
    subQuote: "- Malik Jackson",
    content:
      "“It’s the first thing that I use in the morning so I can start my day feeling fresh and confident”"
  }
].map((item, i) => ({
  ...item,
  key: i + 1,
  altText: `Slide ${i + 1}`,
  imgStyle: {
    objectPosition: "0 20%"
  }
}));

const { base, extension } = getFileParts(ImageConstants.PAPER);

const Testimonials = () => {
  const webp = useWebP();
  const image = `${base}.${webp ? "webp" : extension}`;

  return <Carousel oneImage items={items} $backgroundImage={image} />;
};

export default Testimonials;
