import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { Button } from "../../components/library/Button";
import PictureCarousel from "../../components/library/PictureCarousel";
import { SubHeader, Title } from "../../components/library/Text";
import useMobile from "../../hooks/useMobile";

const Wrapper = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 105px 15px;

  @media screen and (max-width: 768px) {
    padding: 65px 15px;
  }
`;

const AboutCard = styled.div`
  @media screen and (max-width: 768px) {
    padding-bottom: 24px;
  }
`;

const ContentContainer = styled(Container)`
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const Content = styled.div`
  max-width: 495px;

  @media screen and (max-width: 768px) {
    margin-top: 9px;
    max-width: 100%;
  }
`;

const BoxTitle = styled(Title)`
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 25px;
  }
`;

const BoxHeader = styled(SubHeader)`
  @media screen and (max-width: 768px) {
    margin-bottom: 66px;
    font-size: 36px;
    line-height: 43px;
  }
`;

const Info = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: #0e0e0e;
`;

const CarouselContainer = styled.div`
  background-color: #d3d3d3;
  width: 100%;
  height: 100%;
  max-height: 445px;
  max-width: 445px;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    min-height: 300px;
    min-width: 300px;
    max-width: calc(100% - 32px);
  }

  @media screen and (max-width: 343px) {
    min-width: 0;
  }

  @media screen and (min-width: 769px) {
    float: right;
  }
`;

const AboutUsCarousel = styled(PictureCarousel)`
  /* carousel props */
  width: 100%;
  height: 100%;

  .carousel-inner,
  .carousel-item {
    width: 100%;
    height: 100%;
  }

  .carousel-indicators {
    @media screen and (min-width: 769px) {
      display: ${props => (props.hideIndicators ? undefined : "flex")};
    }
  }

  .carousel-indicators li :not(.active) {
    background-color: black;
  }

  /* img props */
  img {
    margin: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const TextContainer = styled.div`
  text-align: center;
`;

const MobileHeader = React.memo(({ isMobile }: { isMobile: boolean }) =>
  !isMobile ? null : (
    <TextContainer>
      <BoxTitle>Forever Fresh</BoxTitle>
      <BoxHeader>That fresh cut feel, bottled to perfection</BoxHeader>
    </TextContainer>
  )
);

const DesktopHeader = React.memo(({ isMobile }: { isMobile: boolean }) =>
  isMobile ? null : (
    <>
      <BoxTitle>Forever Fresh</BoxTitle>
      <BoxHeader>That fresh cut feel, bottled to perfection</BoxHeader>
    </>
  )
);

const ShopNow = styled(Button)`
  width: 100%;

  :active,
  :hover {
    color: white;
    background-color: #4b5a62;
  }
`;

const items = [
  {
    key: "original-design",
    src: "https://static.wavyformula.com/images/wavy-wave-brush.webp",
    altText: "Hand in Jar"
  }
];

const AboutUs = () => {
  const isMobile = useMobile();
  return (
    <Wrapper id="about-us" data-aos="fade-down">
      <MobileHeader isMobile={isMobile} />
      <AboutCard>
        <ContentContainer fluid>
          <Row noGutters={isMobile}>
            <Col md={6}>
              <Content>
                <DesktopHeader isMobile={isMobile} />
                <Info>
                  Before a big meeting, a vacation, a wedding, or that special first date - you make
                  sure to get a haircut, because there's nothing like walking out of the barbershop
                  knowing you look good. With our products, we strive to give you that fresh cut
                  feeling, 365.
                </Info>
                <Info>
                  Our unique Curl & Coil Balm is an incredibly versatile leave-in conditioner that
                  will help you be your most authentic self, anywhere, anytime.
                </Info>
                <Link to="/products">
                  <ShopNow>Shop Now</ShopNow>
                </Link>
              </Content>
            </Col>
            <Col md={6}>
              <CarouselContainer>
                <AboutUsCarousel hideControls hideIndicators interval={false} items={items} />
              </CarouselContainer>
            </Col>
          </Row>
        </ContentContainer>
      </AboutCard>
    </Wrapper>
  );
};

export default AboutUs;
