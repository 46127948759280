import React, { useCallback, useContext, useRef, useState } from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { NavLink } from "../../components/library/Links";
import { ImageConstants } from "../../constants/Images";
import { CartContext, CartProviderProps } from "../../contexts/CartContext";
import { getFileParts } from "../../utils/browser";
import Tracking from "../../utils/tracking";

const StyledCarousel = styled(Carousel)`
  margin-top: -70px;
`;

const Indicators = styled(CarouselIndicators)`
  display: none;
`;

const Item = styled(CarouselItem)`
  height: 679px;

  @media screen and (max-width: 768px) {
    height: 500px;
  }
`;

const Content = styled.div<{ contentCSS?: FlattenSimpleInterpolation }>`
  height: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.contentCSS};

  @media screen and (max-width: 768px) {
    padding: 0;
    max-width: 258px;
    margin: 0 auto;
    text-align: center;
  }
`;

const Block = styled.div`
  max-width: 548px;
`;

const Image = styled.img.attrs(() => ({
  className: "carousel-image"
}))<{ imageCSS?: FlattenSimpleInterpolation }>`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: #c4c4c4;
  ${props => props.imageCSS};

  @media screen and (max-width: 768px) {
    height: calc(100% - 20px);
  }
`;

const CTABlock = styled.div`
  max-width: 367px;
`;

const MainTitle = styled.h2`
  font-family: Quarto;
  font-weight: bold;
  font-size: 84px;
  line-height: 101px;
  color: white;
  margin-bottom: 38px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 26px;
  }
`;

const MainText = styled.p<{ infoCSS?: FlattenSimpleInterpolation }>`
  font-size: 18px;
  line-height: 25px;
  color: white;
  margin-bottom: 56px;
  ${props => props.infoCSS};
`;

const GetWavyButton = styled(NavLink)`
  background-color: #bce0f6;
  width: 235px;
  font-style: normal;
  font-size: 15px;
  line-height: 25px;
  text-align: center;

  :hover {
    background: rgba(85, 186, 234, 0.9);
    text-decoration: none;
  }

  &&& {
    color: #4b5a62;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 200px;
    margin: 0 auto;
    padding: 12px 2.9375rem;
  }
`;

const InnerContent = React.memo(({ infoCSS }: { infoCSS?: FlattenSimpleInterpolation }) => {
  const onClick = useCallback(() => {
    Tracking.fb("ViewContent", {
      content_name: "Wavy Blue Curl & Coil Balm",
      content_type: "product"
    });
  }, []);

  return (
    <>
      <MainTitle>That Fresh Cut Feel, 365</MainTitle>
      <CTABlock>
        <MainText infoCSS={infoCSS}>
          Everyday hair care designed for black men that make waves.
        </MainText>
        <GetWavyButton to="/products" onClick={onClick}>
          Shop Now
        </GetWavyButton>
      </CTABlock>
    </>
  );
});

const carousel1CSS = css`
  ::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  }
`;

const carousel2CSS = css`
  @media screen and (max-width: 768px) {
    object-position: 27% 0%;
  }
`;

const carousel3CSS = css`
  transform: scaleX(-1);

  @media screen and (max-width: 768px) {
    object-position: 75% 0%;
  }
`;

const carousel8ImageCSS = css`
  @media screen and (max-width: 768px) {
    object-position: 39% 0%;
  }
`;

const carousel8ContentCSS = css`
  @media screen and (max-width: 968px) {
    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  @media screen and (max-width: 768px) {
    ::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }
  }

  @media screen and (min-width: 769px) and (max-width: 818px) {
    ::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
  }

  @media screen and (min-width: 819px) and (max-width: 868px) {
    ::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }
  }

  @media screen and (min-width: 869px) and (max-width: 918px) {
    ::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
  }

  @media screen and (min-width: 919px) and (max-width: 968px) {
    ::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
  }
`;

interface ItemProps {
  key: number;
  image: string;
  infoCSS?: FlattenSimpleInterpolation;
  imageCSS?: FlattenSimpleInterpolation;
  contentCSS?: FlattenSimpleInterpolation;
}

const items: ItemProps[] = [
  {
    key: 1,
    image: ImageConstants.MAIN_CAROUSEL_1,
    imageCSS: carousel1CSS,
    contentCSS: carousel8ContentCSS
  },
  {
    key: 2,
    image: ImageConstants.MAIN_CAROUSEL_2,
    imageCSS: carousel2CSS
  },
  {
    key: 3,
    image: ImageConstants.MAIN_CAROUSEL_3,
    imageCSS: carousel3CSS
  },
  {
    key: 4,
    image: ImageConstants.MAIN_CAROUSEL_4
  },
  {
    key: 5,
    image: ImageConstants.MAIN_CAROUSEL_5
  },
  {
    key: 6,
    image: ImageConstants.MAIN_CAROUSEL_6
  },
  {
    key: 7,
    image: ImageConstants.MAIN_CAROUSEL_7
  },
  {
    key: 8,
    image: ImageConstants.MAIN_CAROUSEL_8,
    imageCSS: carousel8ImageCSS,
    contentCSS: carousel8ContentCSS
  }
];

const MainCarousel = () => {
  // hooks
  const animatingRef = useRef(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { isOpen } = cartContext.state;

  // functions
  const next = () => {
    if (animatingRef.current) return;
    setActiveIndex((activeIndex + 1) % items.length);
  };

  const previous = () => {
    if (animatingRef.current) return;
    setActiveIndex((activeIndex - 1 + items.length) % items.length);
  };

  const noop = useCallback(() => {}, []);
  const onExiting = useCallback(() => (animatingRef.current = true), []);
  const onExited = useCallback(() => (animatingRef.current = false), []);

  return (
    <StyledCarousel
      ride="carousel"
      next={next}
      previous={previous}
      activeIndex={activeIndex}
      interval={isOpen ? false : 4000}
    >
      <Indicators items={items} activeIndex={activeIndex} onClickHandler={noop} />
      {items.map((item, i) => {
        const { base } = getFileParts(item.image);
        const srcSet = `
          ${base}@150w.jpg 150w,
          ${base}@300w.jpg 300w,
          ${base}@900w.jpg 900w,
          ${base}@1200w.jpg 1200w,
          ${base}@1500w.jpg 1500w,
          ${base}@1800w.jpg 1800w,
          ${base}@2100w.jpg 2100w
        `;
        const webPsrcSet = `
          ${base}@150w.webp 150w,
          ${base}@300w.webp 300w,
          ${base}@900w.webp 900w,
          ${base}@1200w.webp 1200w,
          ${base}@1500w.webp 1500w,
          ${base}@1800w.webp 1800w,
          ${base}@2100w.webp 2100w
        `;

        return (
          <Item key={item.key} onExiting={onExiting} onExited={onExited}>
            <Content contentCSS={item.contentCSS}>
              <Block>
                <picture>
                  <source type="image/webp" srcSet={webPsrcSet} />
                  <source type="image/jpeg" srcSet={srcSet} />
                  <Image
                    src={`${base}@900w.jpg`}
                    alt={`carousel${i + 1}`}
                    imageCSS={item.imageCSS}
                  />
                </picture>
                <InnerContent infoCSS={item.infoCSS} />
              </Block>
            </Content>
          </Item>
        );
      })}
    </StyledCarousel>
  );
};

export default MainCarousel;
