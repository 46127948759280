import { OutboundLink } from "gatsby-plugin-google-analytics";
import React, { useEffect, useState, useCallback } from "react";
import { Col, Container as RowContainer, Row } from "reactstrap";
import styled from "styled-components";
import { LazyImage } from "../../components/library/Images";
import { SubHeader } from "../../components/library/Text";
import useMobile from "../../hooks/useMobile";
import WavyApi from "../../utils/api";
import SocialCarousel from "./SocialCarousel";

interface Post {
  id: string;
  caption: string;
  type: string;
  url: string;
  permalink: string;
}

const Section = styled.div`
  background: #f9f9f9;
`;

const Wrapper = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 105px 15px;
  @media screen and (max-width: 768px) {
    padding: 18px 15px 62px 15px;
  }
`;

const SocialInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
`;

const Handle = styled(SubHeader)`
  margin-bottom: 36px;

  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: #0e0e0e;
`;

const Container = styled(RowContainer)`
  margin-top: 40px;
`;

const Placeholder = styled.div`
  width: 280px;
  height: 280px;
  margin: 0 auto;
  background: #f2f2f2;

  @media screen and (max-width: 840px) {
    width: 180px;
    height: 180px;
  }

  @media screen and (min-width: 841px) and (max-width: 1150px) {
    width: 200px;
    height: 200px;
  }
`;

const SocialImage = styled(LazyImage)`
  width: 100%;
  height: 100%;
`;

interface LinkType {
  src: string;
  href?: string;
  alt?: string;
}

const defaultLinks = [
  { src: "", href: "" },
  { src: "", href: "" },
  { src: "", href: "" },
  { src: "", href: "" }
];

const formatSrc = (url: string) => {
  const p = url.split("/");
  let t = "";
  for (let i = 0; i < p.length; i++) {
    if (i == 2) {
      t += p[i].replace(/-/g, "--").replace(/\./g, "-") + atob("LnRyYW5zbGF0ZS5nb29n") + "/";
    } else {
      if (i != p.length - 1) {
        t += p[i] + "/";
      } else {
        t += p[i];
      }
    }
  }

  return encodeURI(t);
};

const SocialSection = () => {
  const isMobile = useMobile();
  const [links, setLinks] = useState<LinkType[]>(defaultLinks);

  // get images
  const getImages = useCallback(async () => {
    const res = await WavyApi.getInstagramImages();
    const body = JSON.parse(res);
    const data: Post[] = body.data.data || [];

    const results = data
      .filter(post => post.type === "IMAGE")
      .slice(0, 4)
      .map(post => ({
        src: formatSrc(post.url),
        href: post.permalink,
        alt: post.caption
      }));

    setLinks(results);
  }, []);

  // on init, get IG images
  useEffect(() => {
    // get instagram thumbnails
    getImages();
  }, [getImages]);

  return (
    <Section>
      <Wrapper>
        <SocialInfo>
          <TextWrapper>
            <Handle>
              <OutboundLink
                href="https://www.instagram.com/getwavyblue"
                target="_blank"
                rel="noreferrer"
              >
                @getwavyblue
              </OutboundLink>
            </Handle>
            <Text>Don't forget to use #groomedforgreatness to be featured in our gallery</Text>
          </TextWrapper>
        </SocialInfo>
        {isMobile ? (
          <SocialCarousel items={links} />
        ) : (
          <Container fluid>
            <Row>
              <Col md={3}>
                <Placeholder>
                  <a href={links[0].href} target="_blank" rel="noreferrer">
                    <SocialImage src={links[0].src} alt={links[0].alt} />
                  </a>
                </Placeholder>
              </Col>
              <Col md={3}>
                <Placeholder>
                  <a href={links[1].href} target="_blank" rel="noreferrer">
                    <SocialImage src={links[1].src} alt={links[1].alt} />
                  </a>
                </Placeholder>
              </Col>
              <Col md={3}>
                <Placeholder>
                  <a href={links[2].href} target="_blank" rel="noreferrer">
                    <SocialImage src={links[2].src} alt={links[2].alt} />
                  </a>
                </Placeholder>
              </Col>
              <Col md={3}>
                <Placeholder>
                  <a href={links[3].href} target="_blank" rel="noreferrer">
                    <SocialImage src={links[3].src} alt={links[3].alt} />
                  </a>
                </Placeholder>
              </Col>
            </Row>
          </Container>
        )}
      </Wrapper>
    </Section>
  );
};

export default SocialSection;
