import React from "react";
import styled from "styled-components";

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 176px;
  max-height: 176px;
  border-radius: 50%;
  background: #def0fb;

  ::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const CircleIcon = ({
  children,
  className
}: {
  children?: React.ReactNode;
  className?: string;
}) => <Circle className={className}>{children}</Circle>;

export default CircleIcon;
