import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Animal from "../../images/animal.inline.svg";
import Man from "../../images/man.inline.svg";
import Paraben from "../../images/paraben.inline.svg";
import Usa from "../../images/usa.inline.svg";
import CircleIcon from "../../components/library/CircleIcon";

const Wrapper = styled.div`
  padding: 105px 0;
  background: rgba(234, 235, 237, 0.14);
`;

const OuterCol = styled(Col)`
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Icon = styled(CircleIcon)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 38.45px;

  svg {
    height: 50%;
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    max-width: 86px;
    max-height: 86px;
    margin-bottom: 10px;
  }
`;

const Caption = styled.div`
  margin: 0 auto;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 768px) {
    font-weight: normal;
  }
`;

const RowOne = () => (
  <Row>
    <Col>
      <Icon>
        <Man />
      </Icon>
      <Caption>Black Hair Ready</Caption>
    </Col>
    <Col>
      <Icon>
        <Paraben />
      </Icon>
      <Caption>Paraben Free</Caption>
    </Col>
  </Row>
);

const RowTwo = () => (
  <Row>
    <Col>
      <Icon>
        <Animal />
      </Icon>
      <Caption>Cruelty Free & Vegan</Caption>
    </Col>
    <Col>
      <Icon>
        <Usa />
      </Icon>
      <Caption>Made in the U.S.</Caption>
    </Col>
  </Row>
);

const IconRow = () => (
  <Wrapper>
    <Container>
      <Row>
        <OuterCol md={6}>
          <RowOne />
        </OuterCol>
        <Col md={6}>
          <RowTwo />
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default IconRow;
