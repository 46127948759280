import React, { useCallback, useRef, useState } from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";
import styled from "styled-components";

const Item = styled(CarouselItem)`
  height: 253px;
`;

const Indicators = styled(CarouselIndicators)`
  margin: 0 15%;

  li {
    margin: 0 21px;
    width: 10px;
    height: 10px;
    opacity: 0.4;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  li.active {
    background-color: #7ad4ff;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 207px;
  display: flex;
  justify-content: space-between;
`;

const Placeholder = styled.div`
  /* width: 158.6px; */
  width: calc(50% - 4.85px);
  height: 207px;
  background: #f2f2f2;
`;

const Image = styled.img.attrs(() => ({
  className: "lozad"
}))`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const blocks = [{ key: 0 }, { key: 1 }];

const SocialCarousel = ({ items }) => {
  // hooks
  const animatingRef = useRef(false);
  const [activeIndex, setActiveIndex] = useState(0);

  // functions
  const next = () => {
    if (animatingRef.current) {
      return;
    }

    // update index
    setActiveIndex((activeIndex + 1) % items.length);
  };

  const previous = () => {
    if (animatingRef.current) {
      return;
    }

    // update index
    setActiveIndex((activeIndex - 1 + items.length) % items.length);
  };

  const goToIndex = newIndex => {
    if (animatingRef.current) {
      return;
    }

    // update index
    setActiveIndex(newIndex);
  };

  const onExiting = useCallback(() => (animatingRef.current = true), []);
  const onExited = useCallback(() => (animatingRef.current = false), []);

  return (
    <Carousel interval={false} activeIndex={activeIndex} next={next} previous={previous}>
      {blocks.map((i, index) => {
        const item1 = items[i.key + index];
        const item2 = items[i.key + index + 1];
        return (
          <Item key={i.key} onExiting={onExiting} onExited={onExited}>
            <ImageWrapper>
              <Placeholder>
                <Image src={item1.src} alt={item1.alt} />
              </Placeholder>
              <Placeholder>
                <Image src={item2.src} alt={item2.alt} />
              </Placeholder>
            </ImageWrapper>
          </Item>
        );
      })}
      <Indicators items={blocks} activeIndex={activeIndex} onClickHandler={goToIndex} />
    </Carousel>
  );
};

export default SocialCarousel;
